import moment from 'moment-timezone';
import { Closure } from './types';

export const MENUS: Record<
    string,
    {
        hash: string;
        title?: string;
        order?: number;
        ignore?: boolean;
    }
> = {
    ABOUT_US: { hash: 'about-us', title: 'ÜBER UNS', order: 1 },
    CONTACT: { hash: 'contact', title: 'KONTAKT', order: 6 },
    DRINKS: { hash: 'drinks', title: 'GETRÄNKE', order: 3 },
    EVENTS: { hash: 'events', ignore: true },
    FOOD: { hash: 'food', title: 'SPEISEN', order: 2 },
    GALLERY: { hash: 'gallery', title: 'GALERIE', order: 5 },
    IMPRESSUM: { hash: 'impressum', title: 'IMPRESSUM', order: 7 },
    RESERVATION: { hash: 'reservation', title: 'RESERVIERUNG', order: 4 }
};

export const HEADER_ID = 'header';

export const AMAZON_BASE_URL = 'https://d1oxmbo269r15r.cloudfront.net/stores';

export const BASE_URL =
    'https://personalizedgenericapi.azurewebsites.net/api/Restaurant';

export const SAO_BASE_URL = 'https://backbonedev.scanandorder.eu/api';

export const required = (value: string) => (value ? undefined : 'Erforderliches Feld');

export const getUpdatedClosure = (closure: Closure<string>): Closure => ({
    ...closure,
    effectiveByDate: moment(closure.effectiveByDate),
    endDate: moment(closure.endDate),
    startDate: moment(closure.startDate)
});
