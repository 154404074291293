import { ThunkAction as ThunkActionCreator } from 'redux-thunk';
import { AnyAction } from 'redux';
import { SnackbarKey } from 'notistack';
import { AppStore, Store } from '../types';

export enum ActionType {
    REMOVE_NOTIFICATION = 'REMOVE_NOTIFICATION',
    ENQUEUE_NOTIFICATION = 'ENQUEUE_NOTIFICATION',
    SET_CATEGORIES = 'SET_CATEGORIES',
    SET_PRODUCTS = 'SET_PRODUCTS',
    SET_ALLERGENS = 'SET_ALLERGENS',
    SET_GALLERY_IMAGES = 'SET_GALLERY_IMAGES',
    SET_STORE_CONFIGURATION = 'SET_STORE_CONFIGURATION',
    SET_CLOSURES = 'SET_CLOSURES'
}

interface CloseNotification {
    type: ActionType.REMOVE_NOTIFICATION;
    payload: SnackbarKey;
}

interface EnqueueNotification {
    type: ActionType.ENQUEUE_NOTIFICATION;
    payload: AppStore['notifications'][number];
}

interface SetCategories {
    type: ActionType.SET_CATEGORIES;
    payload: AppStore['categories'];
}

interface SetProducts {
    type: ActionType.SET_PRODUCTS;
    payload: AppStore['products'];
}

interface SetAllergens {
    type: ActionType.SET_ALLERGENS;
    payload: AppStore['allergens'];
}

interface SetGalleryImages {
    type: ActionType.SET_GALLERY_IMAGES;
    payload: AppStore['galleryImages'];
}

interface SetStoreConfiguration {
    type: ActionType.SET_STORE_CONFIGURATION;
    payload: AppStore['storeConfiguration'];
}

interface SetClosures {
    type: ActionType.SET_CLOSURES;
    payload: AppStore['closures'];
}

export type Action =
    | CloseNotification
    | EnqueueNotification
    | SetCategories
    | SetProducts
    | SetAllergens
    | SetGalleryImages
    | SetStoreConfiguration
    | SetClosures;

export type ThunkAction<R = void> = ThunkActionCreator<R, Store, unknown, AnyAction>;
