import * as React from 'react';
import { useSelector } from 'react-redux';
import { Store } from './types';

const withStoreConfig = <P extends Record<string, any>>(
    Component: React.ComponentType<P>
) => {
    const StoreConfig: React.FC<P> = props => {
        const storeConfiguration = useSelector(
            ({ app }: Store) => app.storeConfiguration
        );

        if (!storeConfiguration) {
            return null;
        }

        return <Component {...(props as P)} storeConfiguration={storeConfiguration} />;
    };

    return StoreConfig;
};

export default withStoreConfig;
