import React from 'react';
import { useSelector } from 'react-redux';
import Lightbox from 'react-image-lightbox';
import {
    FacebookIcon,
    FacebookShareButton,
    TwitterIcon,
    TwitterShareButton
} from 'react-share';
import { AMAZON_BASE_URL, MENUS } from './Constants';
import TitleSection from './TitleSection';
import { AppStore, Store } from './types';
import withStoreConfig from './WithStoreConfig';

const Gallery: React.FC<{ storeConfiguration?: AppStore['storeConfiguration'] }> = ({
    storeConfiguration
}) => {
    const [open, setOpen] = React.useState(false);
    const [selectedImage, setSelectedImage] =
        React.useState<AppStore['galleryImages'][number]>();

    const galleryImages = useSelector(({ app }: Store) =>
        app.galleryImages.map(i => ({
            ...i,
            name: `${AMAZON_BASE_URL}/store___${storeConfiguration.shortUniqueID}/websiteGallery/${i.name}`
        }))
    );

    const handleImageClick = React.useCallback(
        (image: AppStore['galleryImages'][number]) => () => {
            setOpen(true);
            setSelectedImage(image);
        },
        []
    );

    const handleLightboxClose = React.useCallback(() => {
        setOpen(false);
        setSelectedImage(null);
    }, []);

    const caption = (
        <div className='share-icons'>
            <TwitterShareButton url={selectedImage?.name} className='icon-share'>
                <TwitterIcon size={30} round className='icon' />
                Share
            </TwitterShareButton>
            <FacebookShareButton url={selectedImage?.name} className='icon-share'>
                <FacebookIcon size={30} round className='icon' /> Post
            </FacebookShareButton>
        </div>
    );

    return (
        <div id={MENUS.GALLERY.hash} className='gallery-section'>
            <div className='container'>
                <TitleSection title='Foto Galerie' />
                <div className='content'>
                    {galleryImages.map(image => (
                        <figure key={image.id} onClick={handleImageClick(image)}>
                            <img src={image.name} />
                        </figure>
                    ))}
                </div>
            </div>
            {open && (
                <Lightbox
                    mainSrc={selectedImage?.name}
                    onCloseRequest={handleLightboxClose}
                    imageCaption={caption}
                />
            )}
        </div>
    );
};

export default withStoreConfig(Gallery);
