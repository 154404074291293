import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar, SnackbarKey } from 'notistack';
import { Store } from './types';
import { removeNotification } from './actions/Actions';

let displayed: SnackbarKey[] = [];

const Notifier: React.FC = () => {
    const dispatch = useDispatch();
    const notifications = useSelector((store: Store) => store.app.notifications);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();

    const storeDisplayed = (id: SnackbarKey) => {
        displayed = displayed.concat(id);
    };

    const removeDisplayed = (id: SnackbarKey) => {
        displayed = displayed.filter(key => id !== key);
    };

    React.useEffect(() => {
        notifications.forEach(({ key, message, options = {} }) => {
            // do nothing if snackbar is already displayed
            if (displayed.includes(key)) {
                return;
            }

            // display snackbar using notistack
            enqueueSnackbar(message, {
                key,
                ...options,
                onExited: (_e, myKey) => {
                    // remove this snackbar from redux store
                    dispatch(removeNotification(myKey));
                    removeDisplayed(myKey);
                }
            });

            // keep track of snackbars that we've displayed
            storeDisplayed(key);
        });
    }, [notifications, closeSnackbar, enqueueSnackbar, dispatch]);

    return null;
};

export default Notifier;
