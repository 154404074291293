import React from 'react';
import _ from 'lodash';
import { HEADER_ID } from './Constants';

const Anchor: React.FC<{
    hash: string;
    className: string;
    onClick?: (hash: string) => void;
}> = ({ hash, className, children, onClick }) => {
    const handleAnchorClick = React.useCallback(() => {
        if (onClick) {
            onClick(hash);
        }

        const elementTop = document.getElementById(hash)?.offsetTop;

        if (_.isNil(elementTop)) {
            return;
        }

        window.scrollTo({
            top: elementTop - document.getElementById(HEADER_ID)?.offsetHeight,
            behavior: 'smooth'
        });
    }, [onClick, hash]);

    return (
        <div className={className} onClick={handleAnchorClick}>
            {children}
        </div>
    );
};

export default Anchor;
